@use 'app-colors' as c;
@use 'app-variables' as v;

.wrapper {
  background-color: c.$base;
  height: 100%;

  .container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 v.$spacer * 3 v.$spacer;

    .header {
      padding: v.$spacer * 3.5 v.$spacer * 2;
      background-color: c.$landing-splash-header-background-color;

      .logoWrapper {

      }
    }

    .main {
      background-color: c.$white;
      border-bottom-left-radius: v.$border-radius;
      border-bottom-right-radius: v.$border-radius;
      // box-shadow: v.$box-shadow;

      .heroWrapper {
        padding: v.$spacer * 1.5 v.$spacer * 2;
        background-color: c.$primary;

        .text {
          font-family: v.$font-family-headings;
          font-size: v.$font-size-base * 2.25;
          font-weight: 600;
          color: c.$white;
        }
      }

      .alertBannerWrapper {
        padding: v.$spacer * 1.5 v.$spacer * 2;
        background-color: c.$yellow;

        .alertBanner {
          .alertText {
            font-size: v.$font-size-base * 1.1;
            font-weight: 600;
          }
        }
      }
    }

    .footer {
      margin-top: v.$spacer * 5;

      .copyright {
        font-size: v.$font-size-base * .9;
        color: c.$grey4;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    .container {
      max-width: 100%;
      padding: 0 0 v.$spacer;
    }
  }
}

@media screen and (max-width: 465px) {
  .wrapper {
    .container {
      max-width: 100%;
      padding: 0 0 v.$spacer;

      .main {

        .heroWrapper {
          padding: v.$spacer v.$spacer * 2 v.$spacer * 1.5;

          .text {
            font-size: v.$font-size-base * 1.9;
          }
        }
      }
    }
  }
}
