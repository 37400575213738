@use 'app-colors' as c;
@use 'app-variables' as v;

.wrapper {
  position: relative;
  // margin-top: v.$spacer * 2;

  .searchWrapper {
    padding: 0 v.$spacer v.$spacer;
    margin-bottom: v.$spacer;
    background-color: c.$grey;

    .searchFormGroup {
      margin-bottom: 0;
    }
  }

  .demoCard {
    text-align: center;
    margin-bottom: v.$spacer;

    .btn {
      margin: 0;
      padding: v.$spacer * .15;
    }

    .demoCardSpacer {
      margin-top: v.$spacer * 1.5;
      font-style: italic;
      font-size: v.$font-size-base * .9;
      color: c.$grey4;
    }
  }

  .loader {
    .loaderIcon {
      width: 36px;
      height: 36px;
    }
  }

  .emptyPatients, .searchInstructions {
    text-align: center;
    font-style: italic;
    padding: v.$spacer 0 v.$spacer * 2;
  }

  .addPatientBtnContainer {
    text-align: center;
  }

  .patientCardsContainer {
    .patientCardContainer {
      margin-bottom: v.$spacer;
    }

    .addPatientCard {
      height: v.$patient-card-height;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

:global {
  @media screen and (max-width: 768px) {
    #__next {
      :local {
        .wrapper {
          .searchWrapper {
            .searchFormGroup {
              margin-bottom: v.$spacer * .5 !important;
            }
          }
        }
      }
    }
  }
}
