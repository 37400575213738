@use 'app-colors' as c;
@use 'app-variables' as v;

.wrapper {
  margin-top: v.$spacer;
  padding: v.$spacer;

  .grid {
    justify-content: center;
  }

  .image {
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }

  .apple {
    height: 43px;
  }

  .google {
    max-width: 163px;
    margin-right: -1rem;
  }
}


@media screen and (max-width: 768px) {
}

@media screen and (max-width: 465px) {
}
