@use 'app-variables' as v;

.banners {
  // max-width: 600px;
  .banner {
    border-radius: v.$border-radius;
  }

  .carouselItem {
    & > div {
      box-shadow: v.$box-shadow;
    }
  }
}

@media screen and (max-width: 768px) {
  .banners {
    margin-top: -(v.$main-content-padding / 2);
    margin-left: -(v.$main-content-padding / 2);
    margin-right: -(v.$main-content-padding / 2);

    .banner {
      border-radius: 0;
    }
  }
}
