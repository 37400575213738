@use 'app-colors' as c;
@use 'app-variables' as v;


.wrapper {
  .infoWrapper {
    .title {
      font-size: v.$font-size-base * 1.1;
      font-family: v.$font-family-headings;
      font-weight: 600;
      color: c.$grey6;
      margin-bottom: v.$spacer;
    }

    .visits {
      .visitCardWrapper {
        .visitCard {
          color: c.$grey7;
          margin-bottom: v.$spacer;
          padding-top: 12px;

          .name {
            display: inline-block;
            font-weight: 600;
          }

          .time {
            color: c.$grey3;
            font-style: italic;
            font-size: v.$font-size-base * .8;
            text-align: right;
            margin-top: 2px;
            margin-bottom: -8px;
          }

          .button {
            font-size: v.$font-size-base * .8;
            font-weight: 600;
          }
        }
      }
    }
  }
}
