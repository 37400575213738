@use 'app-colors' as c;
@use 'app-variables' as v;

.patientCardsWrapper {
  position: relative;
  margin-top: v.$spacer * 2;

  .unlinkedAccountWrapper {
    max-width: 50rem;
    margin: auto;

    .title {
      font-size: v.$font-size-base * 1.2;
      font-family: v.$font-family-headings;
      font-weight: 600;
      margin-bottom: v.$spacer * .5;
    }
  }

  .loader {
    .loaderIcon {
      width: 36px;
      height: 36px;
    }
  }

  .patientCardsContainer {
    .patientCardContainer {
      margin-bottom: v.$spacer;
    }

    .addPatientCard {
      height: v.$patient-card-height;
      display: flex;
      align-items: center;
      justify-content: center;

      .addPatientBtnContainer {

      }
    }
  }
}
