@use 'app-colors' as c;
@use 'app-variables' as v;

.wrapper {
  margin-top: -(v.$spacer);
  margin-bottom: v.$spacer;

  .banner {
    padding: v.$spacer;
    border-radius: v.$border-radius;

    &.normal {
      background-color: c.$yellow;
      color: c.$grey7;
    }

    &.alert {
      background-color: c.$yellow;
      color: c.$grey7;
    }

    &.critical {
      background-color: c.$red;
      color: c.$white;
    }

    .text {
      font-size: v.$font-size-base * 1.1;
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    margin-left: -(v.$main-content-padding / 2);
    margin-right: -(v.$main-content-padding / 2);

    .banner {
      border-radius: 0;
    }
  }
}
