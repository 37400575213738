@use 'app-colors' as c;
@use 'app-variables' as v;


.wrapper {
  margin-top: v.$spacer;

  .infoWrapper {
    background-color: c.$grey;
    padding: v.$spacer;
    border-top-left-radius: v.$border-radius;
    border-top-right-radius: v.$border-radius;

    .facility {
      margin-right: v.$spacer * 2;
    }

    .name {
      font-size: v.$font-size-base * 1.2;
      font-family: v.$font-family-headings;
      font-weight: 500;
    }

    .address {
      color: c.$grey5;
    }
  }
}
