@use 'app-colors' as c;
@use 'app-variables' as v;

.contentWrapper {
  padding: v.$spacer * 3 v.$spacer * 2 0;

  .contentBody {
    font-size: v.$font-size-base;

    .heading {
      font-family: v.$font-family-headings;
      font-size: v.$font-size-base * 1.4;
      font-weight: 700;
      color: c.$grey5;
      padding-bottom: v.$spacer * .5;
    }

    .body {
      color: c.$grey7;

      p {
        max-width: 35rem;
        margin-bottom: v.$spacer * 1.5;
      }

      ol {
        counter-reset: ol-counter;
        list-style: none;
        margin-left: v.$spacer;
        padding-left: v.$spacer * 1.2;

        li {
          counter-increment: ol-counter;
          position: relative;
          margin-bottom: v.$spacer;
          padding-left: v.$spacer * .8;

          &:before {
            content: counter(ol-counter);
            color: c.$primary;
            font-size: v.$font-size-base * .9;
            font-weight: 700;
            position: absolute;
            --size: 24px;
            left: calc(-1 * var(--size));
            line-height: var(--size);
            width: var(--size);
            height: var(--size);
            top: 0;
            background: rgba(c.$primary, .2);
            border-radius: 50%;
            text-align: center;
          }

          .listTitle {
            display: block;
            font-weight: 600;
          }

          .listBody {
            display: block;
            color: c.$grey5;
          }
        }
      }
    }
  }
}

.ctaWrapper {
  padding: v.$spacer * 2;

  .ctaBtn {
    padding: v.$spacer * .6 v.$spacer * 2.5;
    font-size: v.$font-size-base;
  }

  .register {
    background-color: c.$grey;
    border-color: c.$grey;

    &:hover, &:focus, &:active {
      background-color: c.$grey2;
      border: 1px solid c.$grey2;
    }
  }
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 465px) {
  .contentWrapper {
    padding: v.$spacer * 2 v.$spacer * 2 0;

    .contentBody {
      .heading {
        font-size: v.$font-size-base * 1.2;
      }
      .body {
        ol {
          padding-left: v.$spacer;
        }
      }
    }
  }

  .ctaWrapper {

    .ctaButtonContainer {
      flex: 1 0 50% !important;
    }

    .ctaBtn {
      padding: v.$spacer * .8 v.$spacer * 2.5;
    }
  }
}
