@use 'app-colors' as c;
@use 'app-variables' as v;

.patientCard {
  height: v.$patient-card-height;


  .name {
    font-weight: 600;
  }

  .dob {

  }

  .patientMoreToggle {
    color: c.$grey5;
  }

  .dropdownMenu {
    transform: initial !important;
    left: auto !important;
    right: 0 !important;
    top: 100% !important;
  }

  .actions {
    margin-top: v.$spacer * 1.5;
    align-content: stretch;
  }

  .noAccessBtn {
    width: 100%;
    padding: v.$spacer * .46;
    background-color: c.$grey;
    color: c.$grey4;
    border-radius: v.$border-radius;
    text-align: center;
    font-size: v.$font-size-base * .9;
    cursor: not-allowed;
  }

  .noAccessText {
    font-weight: 400;
    font-style: italic;
    color: c.$grey6;

  }

  .recordLink {

  }

  .loader {
    justify-content: center;
  }

  .rightButton {
    margin-left: v.$spacer;
  }

  .btn {
    height: 36px;
  }

  .btnGroup {
    width: 100%;

    .cancelBtn {
      flex: 0 0 auto;
      background-color: rgba(c.$success, .65);
      border-color: rgba(c.$success, .25);
      line-height: 1;
    }
  }

  .noAccessIcon {
    color: c.$primary;
  }

  &.noAccess {
    .name {
      color: c.$grey3;
      font-weight: 400;
    }
  }
}
